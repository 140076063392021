.dream-grid-single .cover {
  padding: 0;
  
  .cover-img {
    height: 256px;
    background-position: center;
    background-size: cover;
    border-radius: inherit;
  }
}
  
.ui.attached.segment {
  border-radius: 15px 15px 0 0;
}

.ui .card .image img{
  max-height: 250px;
  object-fit: cover;
}

.item-right {
  margin-left: auto;
}

.main figure{
  position: relative;
  display: inline-block;
  padding: 0.5em;
  max-width: 800px;
  margin: auto;
  background-color: $black87;
}

.main figure img{
  display: block;
  object-fit: contain;
  height: auto;
  padding: 0;
} 

.main figcaption{
  color: white;
  font-style: italic;
  font-size: smaller;
  padding-top: 0.5em;
  text-align: center;
}
