@import 'common';

body {
  background-position: center;
  background-size: cover;

  .os-host > .os-padding > .os-viewport > .os-content {
    height: auto !important;
  }
}

.flip-container {
  width: 100vw;
  height: 100vh;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;

  &.flip-it {
    .flipper {
      transform: rotateY(180deg);
    }
  }

  .flipper {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 0.75s ease;
  }

  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .front {
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
  }
}

.dream-max-width {
  max-width: 1408px + 256px;
  margin: 0 auto;
}

.inverted {
  a {
    color: #eee !important;

    &:hover {
      color: #fff !important;
    }
  }
}

.dream-404-container {
  position: fixed;
  top: calc(50% - 54px);
  left: 50%;
  width: 100%;
  transform: translate3d(-50%, -50%, 0);

  .ui.header {
    margin-bottom: 2rem;

    .sub.header {
      margin-top: 0.5rem;
    }
  }
}

.ui.header a:not(.ui.button):hover {
  text-decoration: underline;
}

.ui.menu.dream-menu {
  margin-left: 0;
  margin-right: 0;

  &.dream-socials {
    display: flex;
    flex-wrap: wrap;

    .item:first-child {
      padding-left: 0.5rem;
    }
  }

  .item {
    padding: 0.5rem;

    &:first-child {
      padding-left: 1rem;
    }

    i {
      margin: 0;
    }

    a {
      color: $black87;
    }
  }
}

.dream-header {
  display: flex;
  justify-content: center;

  > .content {
    $width: 512px;

    width: $width;
    max-width: $width;
    margin-left: 2rem;
  }

  .ui.horizontal.list {
    line-height: 1rem;
  }

  .tags-with-dropdown {
    .title {
      display: flex;
    }

    .title,
    .content {
      padding: 0 !important;
    }

    .ui.list {
      margin-top: 1rem;
      margin-right: 0.375rem;
    }

    .dropdown-icon {
      position: relative;
      top: 2px;
      right: 1px;
      padding: 0 !important;
      text-decoration: none !important;
      transform-origin: center;
      transition: transform 0.5s ease !important;
    }
  }

  .dream-tags {
    .ui.label {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > .content {
      width: 100%;
      max-width: 100%;
      margin-top: 2rem;
      margin-left: unset;
      text-align: center;
    }

    .tags-with-dropdown {
      .title {
        justify-content: center;
      }
    }
  }
}

.dream-grid {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ui.card.dream-card {
  > .image {
    border-top-left-radius: inherit !important;
    border-top-right-radius: inherit !important;
  }

  &.inverted .content {
    .header > a {
      color: $white87 !important;
    }

    .meta {
      color: $white60;
    }

    .description {
      color: $white87;
    }
  }

  &.inverted .extra {
    color: $white60;
  }

  .content {
    .header {
      margin-bottom: 0;

      a {
        color: $black87 !important;
      }
    }

    .description {
      margin-top: 1rem;
      color: $black87;
      overflow-wrap: break-word;

      img {
        max-width: 100%;
      }

      iframe[id^='twitter-widget-'] {
        width: 100% !important;
      }
    }
  }

  .extra {
    position: relative;
    display: flex;
    align-items: center;
    border-top: none !important;

    .author {
      display: flex;
      align-items: center;
    }

    .reading-time {
      position: absolute;
      right: 1em;
    }
  }
}

.dream-single {
  h1.ui.large.header {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    .sub.header {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: unset;
      }
    }
  }

  .cover {
    margin-bottom: 1.5rem;
  }

  .ui.inverted.segment {
    blockquote {
      color: $white60;
    }
  }

  .inverted > .markdown-body {
    color: $white87;
  }
}

.dream-share {
  a {
    text-decoration: none !important;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 0.5rem;
  }
}

.dream-single-aside {
  position: sticky !important;
  top: 0;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.toc {
  ul {
    padding-left: 2rem;
  }

  li {
    margin: 0.5rem 0;
  }
}

.utterances-comments {
  .utterances {
    max-width: unset;
  }
}

#dream-search {
  position: fixed;
  top: 5%;
  left: 50%;
  width: 30%;
  display: none;
  transform: translateX(-50%);

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  .ui.input {
    width: calc(100% - 2rem);
  }

  &.inverted {
    border: 1px solid #555;
  }

  &.inverted input {
    color: $white87;
  }

  &.inverted .results {
    background: #1b1c1d;
    border: none;

    .result:hover {
      background: #202020;
    }

    .title {
      color: $white87 !important;
    }

    .description {
      color: $white60 !important;
    }
  }
}

@import 'custom';
